<template>
  <div>
    <Modal
      @close="toFogotPassword"
      id="passwordFailed"
      :title="modalTitle"
      @ok="toFogotPassword"
    >
      <p style="text-align:center">{{ modalText }}</p>
    </Modal>
  </div>
</template>
<script>
import AutorizationService from '@/services/AutorizationService';
import Modal from '@/components/Modal.vue';

import modal from '@/mixins/modal';

export default {
  mixins: [modal],
  components: {
    Modal,
  },
  data() {
    return {
      modalText: '',
      modalTitle: '',
    };
  },
  created() {
    this.checkToken();
  },
  methods: {
    async checkToken() {
      try {
        const { headers } = await AutorizationService.confirm({ token: this.$route.query.token });
        localStorage.setItem('token', headers['x-api-key']);
        this.$router.push('/forgot-password?step=last-step');
      } catch (error) {
        console.log(error);
        this.modalText = 'Invalid token. Try again';
        this.modalTitle = 'Registered failed';
        this.showModal('passwordFailed');
      }
    },
    toFogotPassword() {
      this.$router.push('/forgot-password');
    },
  },
};
</script>
